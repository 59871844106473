import { FREE_TRIAL, REQUEST_FOR_DEMO } from "../utils/links";

function Health() {
  return (
    <>
      <div className="home-banner-div">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="banner-content">
                <h1 className="extrabold text-white">
                  Flexio Health: Your Partner in Healthcare Deliveries
                </h1>
                <p className="text-white">
                  We are revolutionizing healthcare deliveries with precision,
                  security, and reliability. Specializing in the safe and timely
                  transport of pharmaceutical products, including
                  over-the-counter and specialty medications, as well as
                  laboratory samples. Our commitment to maintaining the highest
                  standards of safety and confidentiality ensures that patient
                  data and product integrity are always protected. With advanced
                  technology and a highly trained team.
                </p>
                <div className="banner-btn-div">
                  <a href={FREE_TRIAL} className="btn btn-primary">
                    Start a Free Trial
                  </a>
                  <a href={REQUEST_FOR_DEMO} className="btn btn-outline-light">
                    Request Demo
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="health-div">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="health-services-list">
                <div className="health-card hc-blue">
                  <div className="hc-img-div">
                    <img
                      src="/img/pioneers-img.png"
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                  <div className="hc-img-content">
                    <h4 className="text-primary">Pioneers in Puerto Rico</h4>
                    <p>
                      We specialize in healthcare-related deliveries, including
                      pharmaceutical products and laboratory tests to reference
                      labs. We maintain the highest standards of safety and
                      confidentiality to protect both our clients' data and the
                      integrity of the transported items.
                    </p>
                  </div>
                </div>
                <div className="health-card hc-same">
                  <div className="hc-img-div">
                    <img
                      src="/img/types-of-delivery.png"
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                  <div className="hc-img-content">
                    <h4 className="yellow">Types of Delivery</h4>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="tod-tag">
                          <h5>Over the Counter</h5>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="tod-tag">
                          <h5>Specialty Pharma</h5>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="tod-tag">
                          <h5>Laboratory Pickup and Delivery</h5>
                        </div>
                      </div>
                    </div>
                    <p>
                      Experience fast and convenient delivery of
                      over-the-counter pharmaceutical products directly to
                      consumers with our Over the Counter option. For
                      specialized and sensitive medications, our Specialty
                      Pharma service guarantees safe and reliable delivery to
                      patients who need them most. Additionally, our Laboratory
                      Pickup and Delivery service plays a vital role in
                      transporting laboratory tests to reference labs, ensuring
                      efficient, precise, and secure handling of all samples.
                      Trust us to meet your diverse healthcare delivery needs
                      with excellence.
                    </p>
                  </div>
                </div>
                <div className="health-card hc-same">
                  <div className="hc-img-div">
                    <img
                      src="/img/maintaining-delivery-img.png"
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                  <div className="hc-img-content">
                    <h4 className="orange">Maintaining Delivery Standards</h4>

                    <p className="mb-3">
                      Security and confidentiality are the cornerstones of
                      Flexio's operations.
                    </p>
                    <ul className="ps-3 mb-3">
                      <li>
                        <b>HIPAA Training:</b> Our drivers are trained to handle
                        medical information responsibly and comply with data
                        protection laws.
                      </li>
                      <li>
                        <b>Effective Communication:</b> We utilize push
                        notifications in our app to maintain direct contact with
                        drivers handling pharmaceutical deliveries.
                      </li>
                      <li>
                        <b>Standards Compliance:</b> We ensure that patient data
                        protection standards are met at every stage of the
                        delivery process.
                      </li>
                    </ul>
                    <p>
                      Trust Flexio to uphold the highest standards of security
                      and confidentiality in all our operations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flexio-health-div img-bg-primary2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 order-2 order-sm-2 order-md-1">
              <div className="ms-content">
                <h2 className="text-primary">Flexio Health</h2>
                <p className="text-primary">
                  Flexio Health offers tailored delivery solutions for the
                  healthcare sector, handling Specialty Pharma, Over the Counter
                  medications, and laboratory samples with precision. Our
                  dedicated drivers are extensively trained to maintain industry
                  standards and compliance with HIPAA regulations.
                </p>
                <a
                  target={`_blank`}
                  href="https://drive.google.com/file/d/1MPe0asNQSngfNzzY6bKSYwQXLwWLwmsH/view?usp=sharing&export=download"
                  download
                  className="btn btn-primary"
                >
                  Download PDF
                </a>
              </div>
            </div>
            <div className="col-md-6 order-1 order-sm-1 order-md-2">
              <div className="flexio-health-img">
                <img
                  src="/img/flexio-icon.png"
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="certificate-div">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="delivery-title-div text-center">
                <h1 className="text-primary mb-2 mb-sm-2 mb-md-4">
                  Certifications
                </h1>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-6 col-sm-6 col-md-3">
                  <div className="certificate-box text-center mb-4">
                    <img
                      src="/img/certificate.png"
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-3">
                  <div className="certificate-box text-center mb-4">
                    <img
                      src="/img/certificate.png"
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-3">
                  <div className="certificate-box text-center mb-4">
                    <img
                      src="/img/certificate.png"
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-3">
                  <div className="certificate-box text-center mb-4">
                    <img
                      src="/img/certificate.png"
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="get-started-div bg-primary2">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="get-started-title">
                <h6 className="text-primary">GET STARTED</h6>
                <h1>Get your deliveries done today.</h1>
              </div>
              <div className="get-started-btn">
                <a href={REQUEST_FOR_DEMO} className="btn btn-primary">
                  Request Demo
                </a>
                <a href={FREE_TRIAL} className="btn btn-outline-light">
                  Start a Free Trial
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Health;
