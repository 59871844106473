export const team = [
    {
        name: 'Adrián Figueroa',
        title: 'CEO & Co Founder',
        img: '/img/team/Adrian Figueroa - CEO & Co Founder.png'
    },
    {
        name: 'Adrián Medina',
        title: 'VP of Business Development',
        img: '/img/team/Adrian Medina - VP of Business Development.png'
    },
    {
        name: 'Ana Jiménez',
        title: 'Full Sales Representative',
        img: '/img/team/Ana Jimenez - Full Sales Representative.png'
    },
    {
        name: 'Ana Álvarez',
        title: 'Support Specialist',
        img: '/img/team/Ana Alvarez - Support Specialist.png'
    },
    {
        name: 'Ariana Espinosa',
        title: 'Driver Development Manager',
        img: '/img/team/Ariana Espinosa - Driver Development Manager.png'
    },
    {
        name: 'Christopher Owens',
        title: 'Lead Software Developer',
        img: '/img/team/Christopher Owens- Lead Software Developer.png'
    },
    {
        name: 'César Arroyo',
        title: 'Marketing Coordinator',
        img: '/img/team/Cesar Arroyo - Marketing Coordinator.png'
    },
    {
        name: 'Dan Alvarez',
        title: 'Receivables & Payables Assistant',
        img: '/img/team/Dan Alvarez - Receivables & Payables Assistant.png'
    },
    {
        name: 'Edian Reyes',
        title: 'Full - Stack Software Developer',
        img: '/img/team/Edian Reyes Full - Stack Software Developer.png'
    },
    {
        name: 'Fernando Yordan',
        title: 'Chief Technology Officer',
        img: '/img/team/Fernando Yordan - Chief Technology Officer.png'
    },
    {
        name: 'Isabel Raymond',
        title: 'Inside Sales Manager',
        img: '/img/team/Isabel Raymond - Inside Sales Manager.png'
    },
    {
        name: 'Jacqueline Montesinos',
        title: 'Software Dev. (QA and Dev)',
        img: '/img/team/Jacqueline Montesinos - Software Dev. (QA and Dev).png'
    },
    {
        name: 'Johann Gracia',
        title: 'Software Developer',
        img: '/img/team/Johann Gracia - Software Developer.png'
    },
    {
        name: 'Jose Rodriguez',
        title: 'Operations Assistant',
        img: '/img/team/Jose Rodriguez - Operations Assistant.png'
    },
    {
        name: 'Juanita Ortiz',
        title: 'VP of Marketing',
        img: '/img/team/Juanita Ortiz - VP of Marketing.png'
    },
    {
        name: 'Lenin Pulgar',
        title: 'Front-End Dev & Tech PM',
        img: '/img/team/Lenin Pulgar _ Front-End Dev & Tech PM.png'
    },
    {
        name: 'Luisa Vargas',
        title: 'Mobile App Developer',
        img: '/img/team/Luisa Vargas - Mobile App Developer.png'
    },
    {
        name: 'Manuel Melendez',
        title: 'Bookkeeping & Compliance',
        img: '/img/team/Manuel Melendez - Bookkeeping & Compliance.png'
    },
    {
        name: 'Neysha Borrero',
        title: 'Product Owner',
        img: '/img/team/Neysha Borrero - Product Owner.png'
    },
    {
        name: 'Orban Mendoza',
        title: 'Co-Founder & Chief Relations Officer',
        img: '/img/team/Orban Mendoza - Co-Founder & Chief Relations Officer.png'
    },
    {
        name: 'Paola Perez',
        title: 'VP of Operations',
        img: '/img/team/Paola Perez - VP of Operations.png'
    },
    {
        name: 'Raúl Quintero',
        title: 'Support Executive',
        img: '/img/team/Raul Quintero - Support Executive.png'
    },
    {
        name: 'Siul Suarez',
        title: 'Dispatch Manager',
        img: '/img/team/Siul Suarez - Dispatch Manager.png'
    },
    {
        name: 'William Vidal',
        title: 'Chief Financial Officer',
        img: '/img/team/William Vidal - Chief Financial Officer.png'
    },
    {
        name: 'Yhoan Gonzalez',
        title: 'Lead Operations Assistant',
        img: '/img/team/Yhoan Gonzalez - Lead Operations Assistant.png'
    }
]