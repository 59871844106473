export const LOGIN = 'https://app.goflexio.com/';
export const REQUEST_FOR_DEMO = 'https://meetings.hubspot.com/iraymond/demo';
export const BECOME_DRIVER = 'become-driver';
export const REGISTER = 'https://app.goflexio.com/register';
export const FREE_TRIAL = REGISTER;
export const APPLE_PLAY_STORE = "https://apps.apple.com/us/app/flexio-driver/id6444411649";
export const GOOGLE_PLAY_STORE = "https://play.google.com/store/apps/details?id=com.driver.flexio&pli=1";
export const CLIENT_SIGNUP = "https://app.goflexio.com/register";
export const DELIVERY_SERVICES = "delivery";
export const LOGISTICS_SERVICES = "saas";
export const EMAIL_TERMS = "https://support.goflexio.com/";
export const DRIVER_TERMS = "driverterms";
export const COMPANY = "resources";
export const SOLUTIONS = "solutions";
export const PRIVACY = "privacy";
export const COOKIES = "cookies";
export const TERMS = "clientterms";
export const CONTACT = "contact";
export const DRIVER_REGISTER = "https://app.goflexio.com/driver/register";