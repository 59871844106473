export const COOKIES_BY_CAT: Record<string, string[]> = {
  essential: ["__cf_bm", "_cfuvid"],
  functional: ["laboratory-anonymous-id"],
  analytics: ["AMP_MK_gfc4024", "AMP_f430d47a49"],
  marketing: [
    "hubspotutk",
    "__hssrc",
    "__hstc",
    "__hssc",
  ],
};
