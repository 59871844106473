const PrivacyPage = () => {
  return (
    <div className="container my-5 policy-page">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <br />
          <br />
          <br />

          <h1 className="text-center mb-4">Privacy Policy</h1>

          <h2 className="mt-4">1. INTRODUCTION</h2>
          <p>Welcome to FLEXIO</p>
          <p>
            FLEXIO (“us”, “we”, or “our”) operates GOFLEXIO.COM (hereinafter
            referred to as “Service”).
          </p>
          <p>
            Our Privacy Policy governs your visit to GOFLEXIO.COM, and explains
            how we collect, safeguard and disclose information that results from
            your use of our Service.
          </p>
          <p>
            We use your data to provide and improve Service. By using Service,
            you agree to the collection and use of information in accordance
            with this policy. Unless otherwise defined in this Privacy Policy,
            the terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions. Our Terms and Conditions (“Terms”) govern
            all use of our Service and together with the Privacy Policy
            constitutes your agreement with us (“agreement”).
          </p>

          <h2>2. DEFINITIONS</h2>
          <p className="text-justify">
            SERVICE means the GOFLEXIO.COM website operated by FLEXIO
          </p>
          <p className="text-justify">
            PERSONAL DATA means data about a living individual who can be
            identified from those data (or from those and other information
            either in our possession or likely to come into our possession).
          </p>
          <p className="text-justify">
            USAGE DATA is data collected automatically either generated by the
            use of Service or from Service infrastructure itself (for example,
            the duration of a page visit).
          </p>
          <p className="text-justify">
            COOKIES are small files stored on your device (computer or mobile
            device). DATA CONTROLLER means a natural or legal person who (either
            alone or jointly or in common with other persons) determines the
            purposes for which and the manner in which any personal data are, or
            are to be, processed. For the purpose of this Privacy Policy, we are
            a Data Controller of your data. DATA PROCESSORS (OR SERVICE
            PROVIDERS) means any natural or legal person who processes the data
            on behalf of the Data Controller. We may use the services of various
            Service Providers in order to process your data more effectively.
          </p>
          <p className="text-justify">
            DATA SUBJECT is any living individual who is the subject of Personal
            Data.
          </p>
          <p className="text-justify">
            THE USER is the individual using our Service. The User corresponds
            to the Data Subject, who is the subject of Personal Data.
          </p>

          <h2>3. INFORMATION COLLECTION AND USE</h2>
          <p>
            We collect several different types of information for various
            purposes to provide and improve our Service to you.
          </p>

          <h2>4. TYPES OF DATA COLLECTED</h2>
          <p>Personal Data</p>
          <p>
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you (“Personal Data”). Personally identifiable information
            may include, but is not limited to:
          </p>
          <p>1. Email address</p>
          <p>2. First name and last name</p>
          <p>3. Phone number</p>
          <p>4. Address, State, Province, ZIP/Postal code, City</p>
          <p>5. Cookies and Usage Data</p>
          <p>
            We may use your Personal Data to contact you with newsletters,
            marketing or promotional materials and other information that may be
            of interest to you. You may opt out of receiving any, or all, of
            these communications from us by following the unsubscribe link.
          </p>
          <p>Usage Data</p>
          <p>
            We may also collect information that your browser sends whenever you
            visit our Service or when you access Service by or through a mobile
            device (“Usage Data”).
          </p>
          <p>
            This Usage Data may include information such as your computer's
            Internet Protocol address (e.g. IP address), browser type, browser
            version, the pages of our Service that you visit, the time and date
            of your visit, the time spent on those pages, unique device
            identifiers and other diagnostic data. When you access Service with
            a mobile device, this Usage Data may include information such as the
            type of mobile device you use, your mobile device unique ID, the IP
            address of your mobile device, your mobile operating system, the
            type of mobile Internet browser you use, unique device identifiers
            and other diagnostic data.
          </p>
          <p>Location Data</p>
          <p>
            We may use and store information about your location if you give us
            permission to do so (“Location Data”). We use this data to provide
            features of our Service, to improve and customize our Service. You
            can enable or disable location services when you use our Service at
            any time by way of your device settings.
          </p>
          <p>Location Data - Flexio Driver App</p>
          <p>
            To participate as a Flexio Driver App driver, you must permit the
            Flexio Driver App Services to access location services through the
            permission system used by your mobile operating system (“Platform”)
            or browser. We may collect the precise location of your device when
            the Flexio Driver App app is running in the foreground or background
            of your device. We may also derive your approximate location from
            your IP address. We use your location information to verify that you
            are present in your preferred region or city when you begin or
            engage in a delivery through the FLEXIO (a “Delivery”), connect you
            with delivery opportunities in your zone, and track the progress and
            completion of your Deliveries. You can enable the location tracking
            feature through the settings on your device or Platform or when
            prompted by the Flexio Driver App mobile app. If you choose to
            disable the location feature through the settings on your device or
            Platform, FLEXIO will not receive precise location information from
            your device, which will prevent you from being able to Trip and
            receiving delivery opportunities in your area.
          </p>
          <p>Tracking Cookies Data</p>
          <p>
            We use cookies and similar tracking technologies to track the
            activity on our Service and we hold certain information. Cookies are
            files with a small amount of data which may include an anonymous
            unique identifier. Cookies are sent to your browser from a website
            and stored on your device. Other tracking technologies are also used
            such as beacons, tags and scripts to collect and track information
            and to improve and analyze our Service.
          </p>
          <p>
            You can instruct your browser to refuse all cookies or to indicate
            when a cookie is being sent. However, if you do not accept cookies,
            you may not be able to use some portions of our Service. Examples of
            Cookies we use:
          </p>
          <p>
            4.1. Session Cookies: We use Session Cookies to operate our Service.
          </p>
          <p>
            4.2. Preference Cookies: We use Preference Cookies to remember your
            preferences and various settings.
          </p>
          <p>
            4.3. Security Cookies: We use Security Cookies for security
            purposes.
          </p>
          <p>
            4.4. Advertising Cookies: Advertising Cookies are used to serve you
            with advertisements that may be relevant to you and your interests.
          </p>
          <p>Other Data</p>
          <p>
            While using our Service, we may also collect the following
            information: sex, age, date of birth, place of birth, passport
            details, citizenship, registration at place of residence and actual
            address, telephone number (work, mobile), details of documents on
            education, qualification, professional training, employment
            agreements, non-disclosure agreements, information on bonuses and
            compensation, information on marital status, family members, social
            security (or other taxpayer identification) number, office location
            and other data.
          </p>

          <h2>5. USE OF DATA</h2>
          <p>FLEXIO uses the collected data for various purposes:</p>
          <p>1. to provide and maintain our Service;</p>
          <p>2. to notify you about changes to our Service;</p>
          <p>
            3. to allow you to participate in interactive features of our
            Service when you choose to do so;
          </p>
          <p>4. to provide customer support;</p>
          <p>
            5. to gather analysis or valuable information so that we can improve
            our Service;
          </p>
          <p>6. to monitor the usage of our Service;</p>
          <p>7. to detect, prevent and address technical issues;</p>
          <p>8. to fulfill any other purpose for which you provide it;</p>
          <p>
            9. to carry out our obligations and enforce our rights arising from
            any contracts entered into between you and us, including for billing
            and collection;
          </p>
          <p>
            10. to provide you with notices about your account and/or
            subscription, including expiration and renewal notices,
            email-instructions, etc.;{" "}
          </p>
          <p>
            11. to provide you with news, special offers and general information
            about other goods, services and events which we offer that are
            similar to those that you have already purchased or enquired about
            unless you have opted not to receive such information;
          </p>
          <p>
            12. in any other way we may describe when you provide the
            information;
          </p>
          <p>13. for any other purpose with your consent</p>

          <h2>6. RETENTION OF DATA</h2>
          <p>
            We will retain your Personal Data only for as long as is necessary
            for the purposes set out in this Privacy Policy. We will retain and
            use your Personal Data to the extent necessary to comply with our
            legal obligations (for example, if we are required to retain your
            data to comply with applicable laws), resolve disputes, and enforce
            our legal agreements and policies.
          </p>
          <p>
            We will also retain Usage Data for internal analysis purposes. Usage
            Data is generally retained for a shorter period, except when this
            data is used to strengthen the security or to improve the
            functionality of our Service, or we are legally obligated to retain
            this data for longer time periods.
          </p>

          <h2>7. TRANSFER OF DATA</h2>
          <p>
            Your information, including Personal Data, may be transferred to –
            and maintained on – computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ from those of your jurisdiction.
          </p>
          <p>
            If you are located outside United States, Puerto Rico and choose to
            provide information to us, please note that we transfer the data,
            including Personal Data, to United States, Puerto Rico and process
            it there.
          </p>
          <p>
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>
          <p>
            FLEXIO will take all the steps reasonably necessary to ensure that
            your data is treated securely and in accordance with this Privacy
            Policy and no transfer of your Personal Data will take place to an
            organisation or a country unless there are adequate controls in
            place including the security of your data and other personal
            information.
          </p>

          <h2>8. DISCLOSURE OF DATA</h2>
          <p>
            We may disclose personal information that we collect, or you
            provide:
          </p>
          <p>
            1. Disclosure for Law Enforcement. Under certain circumstances, we
            may be required to disclose your Personal Data if required to do so
            by law or in response to valid requests by public authorities.
          </p>
          <p>
            2. Business Transaction. If we or our subsidiaries are involved in a
            merger, acquisition or asset sale, your Personal Data may be
            transferred.
          </p>
          <p>3. Other cases. We may disclose your information also:</p>
          <p>3.1. to our subsidiaries and affiliates;</p>
          <p>
            3.2. to contractors, service providers, and other third parties we
            use to support our business;
          </p>
          <p>3.3. to fulfill the purpose for which you provide it;</p>
          <p>
            3.4. for the purpose of including your company’s logo on our
            website;
          </p>
          <p>
            3.5. for any other purpose disclosed by us when you provide the
            information;
          </p>
          <p>3.6. with your consent in any other cases;</p>
          <p>
            3.7. if we believe disclosure is necessary or appropriate to protect
            the rights, property, or safety of the Company, our customers, or
            others.
          </p>

          <h2>9. SECURITY OF DATA</h2>
          <p>
            The security of your data is important to us but remember that no
            method of transmission over the Internet or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </p>

          <h2>10. SERVICE PROVIDERS</h2>
          <p>
            We may employ third-party companies and individuals to facilitate
            our Service (“Service Providers”), provide Service on our behalf,
            perform Service- related services or assist us in analyzing how our
            Service is used.
          </p>
          <p>
            These third parties have access to your Personal Data only to
            perform these tasks on our behalf and are obligated not to disclose
            or use it for any other purpose.
          </p>

          <h2>11. ANALYTICS</h2>
          <p>
            We may use third-party Service Providers to monitor and analyze the
            use of our Service.
          </p>

          <h2>12. CI/CD TOOLS</h2>
          <p>
            We may use third-party Service Providers to automate the development
            process of our Service.
          </p>

          <h2>13. ADVERTISING</h2>
          <p>
            We may use third-party Service Providers to show advertisements to
            you to help support and maintain our Service.
          </p>

          <h2>14. BEHAVIORAL REMARKETING</h2>
          <p>
            We may use remarketing services to advertise on third party websites
            to you after you visited our Service. We and our third-party vendors
            use cookies to inform, optimise and serve ads based on your past
            visits to our Service.
          </p>

          <h2>15. PAYMENTS</h2>
          <p>
            We may provide paid products and/or services within Service. In that
            case, we use third-party services for payment processing (e.g.
            payment processors).
          </p>
          <p>
            We will not store or collect your payment card details. That
            information is provided directly to our third-party payment
            processors whose use of your personal information is governed by
            their Privacy Policy. These payment processors adhere to the
            standards set by PCI-DSS as managed by the PCI Security Standards
            Council, which is a joint effort of brands like Visa, Mastercard,
            American Express and Discover. PCI-DSS requirements help ensure the
            secure handling of payment information.
          </p>

          <h2>16. LINK TO OTHER SITES</h2>
          <p>
            Our Service may contain links to other sites that are not operated
            by us. If you click a third party link, you will be directed to that
            third party's site. We strongly advise you to review the Privacy
            Policy of every site you visit.
          </p>
          <p>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>
          <p>
            For example, the outlined Privacy Policy has been created using
            PolicyMaker.io, a free web application for generating high-quality
            legal documents. PolicyMaker's online privacy policy generator is an
            easy-to-use free tool for creating an excellent privacy policy
            template for a website, blog, online store or app.
          </p>

          <h2>17. CHILDREN'S PRIVACY</h2>
          <p>
            Our Services are not intended for use by children under the age of
            18 (“Child” or “Children”).
          </p>
          <p>
            We do not knowingly collect personally identifiable information from
            Children under 18. If you become aware that a Child has provided us
            with Personal Data, please contact us. If we become aware that we
            have collected Personal Data from Children without verification of
            parental consent, we take steps to remove that information from our
            servers.
          </p>

          <h2>18. CHANGES TO THIS PRIVACY POLICY</h2>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update
            “effective date” at the top of this Privacy Policy.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>

          <h2>19. CONTACT US</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us by
          </p>
          <p>email: support@goflexio.com</p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPage;
