import { FREE_TRIAL, REQUEST_FOR_DEMO } from "../utils/links";

function Saas() {
  return (
    <>
      <div className="home-banner-div">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="banner-content">
                <h1 className="extrabold text-white">
                  Route Planning and Optimization Software
                </h1>
                <p className="text-white">
                  We are setting a new standard in routing and driver management
                  solutions for SMBs. As an AI-backed route planning and
                  optimization platform, we help businesses elevate their
                  operations to the next level. Our simple, accessible, and
                  cost-effective solution makes Flexio a must-have for all SMBs
                  looking to improve efficiency and reduce costs. Embrace Flexio
                  to streamline your delivery processes and stay ahead of the
                  competition.
                </p>
                <div className="banner-btn-div">
                  <a href={FREE_TRIAL} className="btn btn-primary">
                    Start a Free Trial
                  </a>
                  <a href={REQUEST_FOR_DEMO} className="btn btn-outline-light">
                    Request Demo
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="start-trial-div">
        <div className="container">
          <div className="row">
            <div className="col-md-12 d-md-block d-none">
              <div className="trial-feature-img">
                <img src="/img/img.png" alt="img" className="img-fluid" />
              </div>
            </div>
            <div className="col-md-6 d-md-none">
              <div className="trial-feature-img">
                <img
                  src="/img/benefits-img.png"
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-6 d-md-none">
              <div className="trial-feature-img">
                <img
                  src="/img/key-features-img.png"
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
            {/*<div className="col-md-12">*/}
            {/*    <div className="trial-btn-div">*/}
            {/*        <a href={FREE_TRIAL} className="btn btn-primary">*/}
            {/*            Start a Free Trial today!*/}
            {/*        </a>*/}
            {/*    </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
      <div className="saas-feature-div">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-4 col-xl-3 saas-col">
              <div className="feature-card">
                <div className="fc-icon">
                  <img src="/img/time.png" alt="icon" className="img-fluid" />
                </div>
                <div className="fc-content">
                  <h5 className="text-primary">Any Type of Delivery</h5>
                  <p>
                    Our software can withstand any type of vehicle and delivery.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3 saas-col">
              <div className="feature-card">
                <div className="fc-icon">
                  <img src="/img/time2.png" alt="icon" className="img-fluid" />
                </div>
                <div className="fc-content">
                  <h5 className="text-primary">On-Demand Deliveries</h5>
                  <p>
                    Our On-Demand Deliveries provide fast, reliable service for
                    urgent or scheduled packages, ensuring prompt and secure
                    delivery.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3 saas-col">
              <div className="feature-card">
                <div className="fc-icon">
                  <img src="/img/time3.png" alt="icon" className="img-fluid" />
                </div>
                <div className="fc-content">
                  <h5 className="text-primary">
                    Last-Mile & Scheduled Deliveries
                  </h5>
                  <p>
                    Ensure timely and efficient last-mile and scheduled
                    deliveries, meeting your precise needs every time.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3 saas-col">
              <div className="feature-card">
                <div className="fc-icon">
                  <img src="/img/time4.png" alt="icon" className="img-fluid" />
                </div>
                <div className="fc-content">
                  <h5 className="text-primary">Field Resources Management</h5>
                  <p>
                    Optimize your operations with Flexio's Field Resources
                    Management, streamlining tasks and enhancing productivity.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3 saas-col">
              <div className="feature-card">
                <div className="fc-icon">
                  <img src="/img/time5.png" alt="icon" className="img-fluid" />
                </div>
                <div className="fc-content">
                  <h5 className="text-primary">Recruiting & HR</h5>
                  <p>
                    Streamline your delivery operations with Flexio's Recruiting
                    & HR services, connecting you with the best drivers to
                    enhance your team.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3 saas-col">
              <div className="feature-card">
                <div className="fc-icon">
                  <img src="/img/time6.png" alt="icon" className="img-fluid" />
                </div>
                <div className="fc-content">
                  <h5 className="text-primary">Custom Services & Industries</h5>
                  <p>
                    Our solutions are designed to meet the unique needs of
                    various industries.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3 saas-col">
              <div className="feature-card">
                <div className="fc-icon">
                  <img src="/img/time7.png" alt="icon" className="img-fluid" />
                </div>
                <div className="fc-content">
                  <h5 className="text-primary">Invoicing & Payments</h5>
                  <p>
                    Manage your finances effortlessly with Flexio's Client
                    Invoicing & Payments, ensuring smooth and accurate
                    transactions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3 saas-col">
              <div className="feature-card">
                <div className="fc-icon">
                  <img src="/img/time8.png" alt="icon" className="img-fluid" />
                </div>
                <div className="fc-content">
                  <h5 className="text-primary">Custom Processes</h5>
                  <p>
                    We have the ability to create a custom operational process
                    that meet your exact needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3 saas-col">
              <div className="feature-card">
                <div className="fc-icon">
                  <img src="/img/time9.png" alt="icon" className="img-fluid" />
                </div>
                <div className="fc-content">
                  <h5 className="text-primary">
                    Resources Rates Management & Payouts
                  </h5>
                  <p>
                    Flexio offers comprehensive Resources Rates Management &
                    Payouts, ensuring fair and timely compensation for your team
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-5 saas-col">
              <div className="feature-card">
                <div className="fc-icon">
                  <img src="/img/time10.png" alt="icon" className="img-fluid" />
                </div>
                <div className="fc-content">
                  <h5 className="text-primary">
                    Specialized Contractors Market
                  </h5>
                  <p>
                    Thanks to our powerful Recruiting Structure and Flexible
                    Service and Pricing Structures, we can create an on-demand
                    contractor market easily. For example, we can recruit for
                    people that have a certain certification or experience, and
                    make that available for companies to hire on-demand or for a
                    certain time range.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-4 saas-col">
              <div className="feature-card">
                <div className="fc-icon">
                  <img src="/img/time11.png" alt="icon" className="img-fluid" />
                </div>
                <div className="fc-content">
                  <h5 className="text-primary">Localized Task Management</h5>
                  <p>
                    If you have projects that require exact locations, for
                    example, pothole fixing in your area, you can efficiently
                    create the jobs with Flexio. We also have many types of
                    validations to confirm each job is correctly done.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="get-started-div bg-primary2">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="get-started-title">
                <h6 className="text-primary">GET STARTED</h6>
                <h1>Get your deliveries done today.</h1>
              </div>
              <div className="get-started-btn">
                <a href={REQUEST_FOR_DEMO} className="btn btn-primary">
                  Request Demo
                </a>
                <a href={FREE_TRIAL} className="btn btn-outline-light">
                  Start a Free Trial
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Saas;
