import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";

import { COOKIES } from "../../utils/links";

import "./cookies-consent.css";
import { COOKIES_BY_CAT } from "../../utils/cookies";

interface PreferencesState {
  essential: boolean;
  functional: boolean;
  analytics: boolean;
  marketing: boolean;
}

const CookieConsent = () => {
  const [cookies, _, removeCookie] = useCookies();
  const [showBanner, setShowBanner] = useState(false);
  const [preferences, setPreferences] = useState<PreferencesState>({
    essential: true, // Las cookies esenciales están siempre activadas
    functional: false,
    analytics: false,
    marketing: false,
  });

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");

    if (!cookiesAccepted) {
      setShowBanner(true);
    }
  }, []);

  const handleAcceptAll = () => {
    const cookiesNames = Object.keys(cookies);
    localStorage.setItem("cookiesAccepted", JSON.stringify(cookiesNames));
    setShowBanner(false);
  };

  const handleSavePreferences = () => {
    const categories = Object.keys(preferences);
    const acceptedCategories = categories.filter(
      (key) => preferences[key as keyof PreferencesState]
    );
    const rejectedCategories = categories.filter(
      (key) => !preferences[key as keyof PreferencesState]
    );
    let acceptedCookies: string[] = [];
    let rejectedCookies: string[] = [];

    acceptedCategories.forEach((category) => {
      if (COOKIES_BY_CAT[category]) {
        acceptedCookies.push(...COOKIES_BY_CAT[category]);
      }
    });

    rejectedCategories.forEach((category) => {
      if (COOKIES_BY_CAT[category]) {
        rejectedCookies.push(...COOKIES_BY_CAT[category]);
      }
    });

    rejectedCookies.forEach((cookieName) => removeCookie(cookieName));

    localStorage.setItem("cookiesAccepted", JSON.stringify(acceptedCookies));
    setShowBanner(false);
  };

  const handleRejectAll = () => {
    const cookiesNames = Object.keys(cookies);

    cookiesNames.forEach((cookieName) => {
      removeCookie(cookieName);
    });

    localStorage.setItem("cookiesAccepted", JSON.stringify([]));
    setShowBanner(false);
  };

  const handleToggle = (category: keyof PreferencesState) => {
    setPreferences((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  if (!showBanner) return null;

  return (
    <div className="card cookie-banner">
      <div id="cookieSvg">
        {/* Puedes añadir aquí un SVG de icono de cookie */}
      </div>
      <h2 className="cookieHeading">Cookies Consent</h2>
      <p className="cookieDescription">
        We use cookies to improve your experience on our website, analyze usage,
        and provide tailored content and advertising. By continuing to browse,
        you agree to our use of cookies. For more information, see our{" "}
        <Link to={COOKIES}>Cookies Policy.</Link>
      </p>
      <div className="cookie-categories">
        <label>
          <input
            type="checkbox"
            checked={preferences.essential}
            onChange={() => handleToggle("essential")}         
          />
          Essential Cookies
        </label>
        <label>
          <input
            type="checkbox"
            checked={preferences.functional}
            onChange={() => handleToggle("functional")}
          />
          Functionality Cookies
        </label>
        <label>
          <input
            type="checkbox"
            checked={preferences.analytics}
            onChange={() => handleToggle("analytics")}
          />
          Performance and Analytics Cookies
        </label>
        <label>
          <input
            type="checkbox"
            checked={preferences.marketing}
            onChange={() => handleToggle("marketing")}
          />
          Advertising and Targeting Cookies
        </label>
      </div>
      <div className="buttonContainer">
        <button onClick={handleAcceptAll} className="acceptButton">
          Accept All
        </button>
        <button onClick={handleSavePreferences} className="acceptButton">
          Save preferences
        </button>
        <button onClick={handleRejectAll} className="declineButton">
          Reject All
        </button>
      </div>
    </div>
  );
};

export default CookieConsent;
