import {useEffect} from "react";
import {team} from "../utils/team";

function AboutUs() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @TS-ignore
            if ((window as any).hbspt) {
                // @TS-ignore
                (window as any).hbspt.forms.create({
                    region: "na1",
                    portalId: "40168461",
                    formId: "4e816b10-20d3-4629-bb83-d2d647f88f44",
                    target: '#hubspotForm'
                })
            }
        });
    }, []);

    function chunkArray(arr: any[], size: number) {
        return arr.reduce((result, item, index) => {
            const chunkIndex = Math.floor(index / size);
            if (!result[chunkIndex]) {
                result[chunkIndex] = []; // Start a new chunk
            }
            result[chunkIndex].push(item);
            return result;
        }, []);
    }

    return (
        <>
            <div className="home-banner-div">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner-content">
                                <h1 className="extrabold text-white text-center">
                                    About Us
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="aboutus-div overflow-hidden">
                <div className="row position-relative about-content-row">
                    <div className="col-md-12 order-2 order-sm-2 order-md-1 p-0">
                        <div className="container">
                            <div className="row justify-content-end align-items-center">
                                <div className="col-md-6">
                                    <div className="about-content">
                                        <h4>This is Flexio</h4>
                                        <h2 className="text-primary2">
                                            Your Logistics Partner
                                        </h2>
                                        <p className="text-white">
                                            Elevate your delivery operations with our cutting edge logistics solutions!
                                            Powered by advanced route planning and optimization software, alongside our
                                            efficient outsourced overflow management service, Flexio ensures unmatched
                                            efficiency and reliability for medium and last-mile deliveries. Whether for
                                            businesses or individuals, our innovative hybrid approach guarantees a
                                            seamless delivery experience.
                                            <b>Step into the future of logistics with Flexio today!</b>
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="about-img mb-4 mb-sm-4 mb-md-0">
                            <img src="/img/about-img.png" alt="img" className="img-fluid"/>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="meet-our-team-content">
                                <h1>
                                    Meet our team
                                </h1>
                                <p>
                                    Our team is committed to becoming the premier company in route planning,
                                    optimization, and last-mile delivery solutions. Every day, we strive to exceed our
                                    clients' expectations by harnessing our expertise and innovative spirit. With a
                                    relentless focus on achieving your goals, we are here to revolutionize your
                                    logistics experience and ensure seamless deliveries that propel your business
                                    forward. Join us in shaping the future of delivery services with dedication and
                                    excellence at Flexio.
                                </p>
                                <h5>
                                    Follow us online
                                </h5>
                                <div className="follow-us">
                                <a href="https://www.linkedin.com/company/goflexio"><i className="fab fa-linkedin-in"></i></a>
                                <a href="https://www.facebook.com/goflexiopr/"><i className="fab fa-facebook-f"></i></a>
                                <a href="https://www.instagram.com/go_flexio/"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="team-slider">
                                <div className="team-carousel owl-carousel owl-theme">
                                    {chunkArray(team, 2).map((items: any[], cindex: number) => (
                                        <div className={'item'} key={cindex}>
                                            {items.map((item, index) => (
                                                <div className="team-box" key={index}>
                                                    <div className="team-img">
                                                        <img src={item.img} alt={item.name} className={'img-fluid'}/>
                                                    </div>
                                                    <div className="team-name">
                                                        <h5>{item.name}</h5>
                                                        <p>
                                                            {item.title}
                                                        </p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="delivery-form-div">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="hiw-tile text-center pb-0 pb-sm-0 pb-md-5">
                                <h1 className="text-primary">
                                    Ready to transform your delivery experience?
                                </h1>
                                <p className="text-primary">
                                    Start optimizing your logistics and delivery processes with Flexio.
                                </p>
                            </div>
                            <div className="delivery-form">
                                <form id={'hubspotForm'}></form>
                                {/*<form>*/}
                                {/*    <div className="row">*/}
                                {/*        <div className="col-md-6">*/}
                                {/*            <div className="form-group">*/}
                                {/*                <input type="text" id="First_Name" className="form-control"*/}
                                {/*                       placeholder="First Name"/>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        <div className="col-md-6">*/}
                                {/*            <div className="form-group">*/}
                                {/*                <input type="text" id="Last_name" className="form-control"*/}
                                {/*                       placeholder="Last Name"/>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        <div className="col-md-12">*/}
                                {/*            <div className="form-group">*/}
                                {/*                <input type="email" id="Email" className="form-control"*/}
                                {/*                       placeholder="Email"/>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        <div className="col-md-12">*/}
                                {/*            <div className="form-group">*/}
                                {/*                <textarea id="Questions" className="form-control"*/}
                                {/*                          placeholder="Your questions"></textarea>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        <div className="col-md-12">*/}
                                {/*            <div className="form-btn-div">*/}
                                {/*                <button className="btn btn-primary w-100">*/}
                                {/*                    Contact us*/}
                                {/*                </button>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</form>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutUs