import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {blogs} from "../utils/blogs";

function Blog() {
    const params = useParams();
    const [blog, setBlog] = useState<any>(null);
    const navigate = useNavigate();
    useEffect(() => {
        if (params && params.slug) {
            let finBlog = blogs.find(t => t.slug === params.slug);
            if (finBlog) {
                setBlog(finBlog as any);
            } else {
                navigate('/404');
            }
        }
    }, [navigate, params]);
    if (!blog) {
        return null
    }

    return (
        <>
            <div className="home-banner-div">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner-content">
                                <h1 className="extrabold text-white text-center">
                                    {blog.title}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="who-it-work-div">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="resource-detail-box text-center">
                                <div className='resource-content-div'>
                                    <h1 className="text-primary text-start mb-2 bold">
                                        {blog.title}
                                    </h1>
                                    {/*<div className="auther-div d-flex gap-3 mb-4">*/}
                                    {/*    <p>*/}
                                    {/*        <i className="far fa-user"></i> <b>John Doe, </b>*/}
                                    {/*    </p>*/}
                                    {/*    <p>*/}
                                    {/*        <i className="far fa-calendar-alt"></i> 10 September 2024*/}
                                    {/*    </p>*/}
                                    {/*</div>*/}

                                    {blog.img &&
                                        <div className='resource-img-div mb-4'>
                                            <img src={blog.img} alt="icon" className="img-fluid"/>
                                        </div>}

                                    <div dangerouslySetInnerHTML={{__html: blog.description}} className="text-start">
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Blog