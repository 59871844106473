import { useLocation } from "react-router-dom";
import { FREE_TRIAL, LOGIN } from "../utils/links";

function Header() {
  const location = useLocation();

  return (
    <header className="main-header">
      <nav className="navbar navbar-expand-lg bg-transparent">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src="/img/logo.png" alt="logo" width="134" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-bars"></i>
          </button>
          <div
            className="collapse navbar-collapse header-menu-div"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav m-auto">
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    location.pathname === "/" ? "active" : ""
                  }`}
                  aria-current="page"
                  href="/"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    location.pathname === "/saas" ? "active" : ""
                  }`}
                  href="/saas"
                >
                  Products
                </a>
              </li>
              <li className="nav-item dropdown header-dropdown">
                <a
                  className={`nav-link dropdown-toggle ${
                    location.pathname === "/delivery" ||
                    location.pathname === "/health"
                      ? "active"
                      : ""
                  }`}
                  href="#"
                  id="navbarDarkDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Solutions
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDarkDropdownMenuLink"
                >
                  <li>
                    <a className="dropdown-item" href="/saas">
                      Delivery Management Software
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/delivery">
                      Delivery Outsourcing
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/health">
                      Health
                    </a>
                  </li>
                  {/*<li><a className="dropdown-item" href="#">Solution #3</a></li>*/}
                </ul>
              </li>

              <li className="nav-item">
                <a
                  className={`nav-link ${
                    location.pathname === "/become-driver" ? "active" : ""
                  }`}
                  href="/become-driver"
                >
                  Become a Driver
                </a>
              </li>

              <li className="nav-item">
                <a
                  className={`nav-link ${
                    location.pathname === "/resources" ? "active" : ""
                  }`}
                  href="/resources"
                >
                  Resources
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    location.pathname === "/about-us" ? "active" : ""
                  }`}
                  href="/about-us"
                >
                  About Us
                </a>
              </li>
            </ul>
            <div className="header-btn-div">
              <a href={FREE_TRIAL} className="btn btn-light">
                Start a Free Trial
              </a>
              <a href={LOGIN} className="login-btn">
                Login
              </a>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
