import { Link } from "react-router-dom";
import {
  BECOME_DRIVER,
  CLIENT_SIGNUP,
  COMPANY,
  CONTACT,
  COOKIES,
  DELIVERY_SERVICES,
  DRIVER_TERMS,
  EMAIL_TERMS,
  PRIVACY,
  SOLUTIONS,
  TERMS,
} from "../utils/links";

function Footer() {
  return (
    <footer className="main-footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="footer-logo">
              <img src="/img/logo-white.png" alt="logo" className="img-fluid" />
            </div>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-sm-4 col-md-4">
                <div className="footer-box">
                  <h4>Get Started</h4>
                  <ul className="footer-link">
                    <li>
                      <a href={CLIENT_SIGNUP}>Client Sign Up</a>
                    </li>
                    <li>
                      <a href={BECOME_DRIVER}>Become a Driver</a>
                    </li>
                    <li>
                      <a href={DELIVERY_SERVICES}>Delivery Services</a>
                    </li>
                    <li>
                      <a href={"/saas"}>Logistic Software</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-4 col-md-4">
                <div className="footer-box">
                  <h4>Support</h4>
                  <ul className="footer-link">
                    <li>
                      <a href={EMAIL_TERMS}>Support Page</a>
                    </li>
                    <li>
                      <a href={DRIVER_TERMS}>Driver Terms of Service</a>
                    </li>
                    <li>
                      <a href={DELIVERY_SERVICES}>Delivery Services</a>
                    </li>
                    <li>
                      <a href={"/saas"}>Logistic Software</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-4 col-md-4">
                <div className="footer-box">
                  <h4>Company</h4>
                  <ul className="footer-link">
                    <li>
                      <a href={COMPANY}>Company</a>
                    </li>
                    <li>
                      <a href={SOLUTIONS}>Solutions</a>
                    </li>
                    <li>
                      <a href={CONTACT}>Contact Us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-footer">
          <div className="row">
            <div className="col-sm-4 col-md-4 order-2 order-sm-1 order-md-1">
              <div className="footer-social-box">
                <a href="https://www.linkedin.com/company/goflexio">
                  <i className="fab fa-linkedin-in"></i>
                </a>
                <a href="https://www.facebook.com/goflexiopr/">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="https://www.instagram.com/go_flexio/">
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
              <div className="copyright-text">
                <p>Copyright © {new Date().getFullYear()} Flexio</p>
              </div>
            </div>
            <div className="col-sm-8 col-md-8 order-1 order-sm-2 order-md-2">
              <div className="row">
                <div className="col-12 col-sm-6 col-md-4">
                  <div className="footer-box mb-0">
                    <ul className="footer-link">
                      <li>
                        <Link to={PRIVACY}>Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to={COOKIES}>Privacy Cookies</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  <div className="footer-box mb-0">
                    <ul className="footer-link">
                      <li>
                        <a href={TERMS}>Terms and Conditions</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
