
const CookiesPage = () => {
  const currentDate = new Date();

  const formatDate = (date: Date) => {
    return date.getFullYear()
  }

  return (
    <div className="container my-5 policy-page">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <br /><br /><br />

          <h1 className="text-center mb-4">Cookies Policy</h1>

          <h2 className="mt-4">Effective Date: {formatDate(currentDate)}</h2>
          <h2>Introduction</h2>
          <p className="text-justify">Flexio (“we,” “us,” or “our”) uses cookies and similar tracking technologies on our website goflexio.com (the “Site”) to enhance your experience, understand usage patterns, and provide tailored content and advertising. This Cookies Policy explains what cookies are, the types of cookies we use, and your choices regarding their use.</p>

          <h2>What Are Cookies?</h2>
          <p>Cookies are small text files placed on your device when you visit a website. They allow the website to recognize your device, remember your preferences, and improve functionality. Other technologies similar to cookies include pixels, beacons, and tags.</p>

          <h2>Types of Cookies We Use</h2>
          <p><strong>1. Essential Cookies:</strong> These are necessary for the operation of our Site, enabling features like security and access to specific areas.</p>
          <p><strong>2. Performance and Analytics Cookies:</strong> These cookies collect anonymous data on how visitors use our Site, helping us improve functionality and performance.</p>
          <p><strong>3. Functionality Cookies:</strong> These cookies remember your preferences and settings to provide a more personalized experience on our Site.</p>
          <p><strong>4. Advertising and Targeting Cookies:</strong> These cookies track browsing habits to deliver relevant advertising and prevent repeat ads.</p>

          <h2>Third-Party Cookies</h2>
          <p>We may partner with third-party service providers who may use cookies on our Site for analytics, marketing, and other purposes. These providers may collect information about your online activities over time and across other websites.</p>

          <h2>Your Cookie Choices</h2>
          <p>You can adjust your cookie preferences through your browser settings, allowing you to block or delete cookies. However, this may affect the functionality of certain parts of the Site. For more information, visit your browser’s support site or refer to resources such as <a href="https://www.allaboutcookies.org" target="_blank" rel="noopener noreferrer">www.allaboutcookies.org</a>.</p>

          <h2>Updates to This Cookies Policy</h2>
          <p>We may update this Cookies Policy periodically. Changes will be effective when posted, so please review this policy regularly.</p>
        </div>
      </div>
    </div>
  );
};

export default CookiesPage;
