import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import CookieConsent from "./components/cookies-consent/cookies-consent";
import Header from "./components/header";
import ScrollToTop from "./components/scroll-to-top";

import "./App.css";

import Home from "./pages/home";
import ErrorPage from "./pages/ErrorPage";
import Footer from "./components/footer";
import Saas from "./pages/saas";
import AboutUs from "./pages/about-us";
import Delivery from "./pages/delivery";
import Health from "./pages/health";
import BecomeDriver from "./pages/become-driver";
import Resources from "./pages/resources";
import Blog from "./pages/blog";
import PrivacyPage from "./pages/privacy";
import ClienttermsPage from "./pages/clientterms";
import DrivertermsPage from "./pages/driverterms";
import ContactUs from "./pages/contact";
import CookiesPage from "./pages/cookies";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <CookieConsent />
      <Header />

        <Suspense fallback={null}>
      <Routes>
          <Route path={"/"} element={<Home />} />
          <Route path={"/saas"} element={<Saas />} />
          <Route path={"/delivery"} element={<Delivery />} />
          <Route path={"/health"} element={<Health />} />
          <Route path={"/resources"} element={<Resources />} />
          <Route path={"/become-driver"} element={<BecomeDriver />} />
          <Route path={"/about-us"} element={<AboutUs />} />
          <Route path={"/blog/:slug"} element={<Blog />} />
          <Route path={"/privacy"} element={<PrivacyPage />} />
          <Route path={"/cookies"} element={<CookiesPage />} />
          <Route path={"/clientterms"} element={<ClienttermsPage />} />
          <Route path={"/driverterms"} element={<DrivertermsPage />} />
          <Route path={"/contact"} element={<ContactUs />} />
          <Route path="/*" element={<ErrorPage />} />
      </Routes>
        </Suspense>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
