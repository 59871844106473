import {FREE_TRIAL, REQUEST_FOR_DEMO} from "../utils/links";

function Delivery() {
    return (
        <>
            <div className="home-banner-div">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="banner-content">
                                <h1 className="extrabold text-white">
                                    Delivery Overflow Management
                                </h1>
                                <p className="text-white">
                                    Our Dedicated Vehicle Services offer cost-effective solutions for medium to high
                                    volume needs, ideal for custom processes and rush deliveries. With Outsourcing
                                    Overflow Management, handle peak demands efficiently. Simply fill the vehicle and
                                    pay for distance and time.
                                </p>
                                <div className="banner-btn-div">
                                    <a href={FREE_TRIAL} className="btn btn-primary">
                                        Start a Free Trial
                                    </a>
                                    <a href={REQUEST_FOR_DEMO} className="btn btn-outline-light">
                                        Request Demo
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="delivery-tag-div">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="delivery-tag-list">
                                <div className="tag-box">
                                    <h4>
                                        Routing<br/>
                                        Engine
                                    </h4>
                                </div>
                                <div className="tag-box">
                                    <h4>
                                        Personalized<br/>
                                        Workflow
                                    </h4>
                                </div>


                                <div className="tag-box">
                                    <h4>
                                        Real Time<br/>
                                        Visibility
                                    </h4>
                                </div>
                                <div className="tag-box">
                                    <h4>
                                        POD's &<br/>
                                        COD's
                                    </h4>
                                </div>
                                <div className="tag-box">
                                    <h4>
                                        Preferred<br/>
                                        Drivers
                                    </h4>
                                </div>
                                <div className="tag-box">
                                    <h4>
                                        Our Unique<br/>
                                        Delivery Services
                                    </h4>
                                </div>
                                <div className="tag-box">
                                    <h4>
                                        Standard<br/>
                                        Rates
                                    </h4>
                                </div>
                                <div className="tag-box">
                                    <h4>
                                        Branding<br/>
                                        Experience
                                    </h4>
                                </div>
                                <div className="tag-box">
                                    <h4>
                                        Data and<br/>
                                        Analytics
                                    </h4>
                                </div>
                                <div className="tag-box">
                                    <h4>
                                        Dedicated<br/>
                                        Vehicles
                                    </h4>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="delivery-services-div">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="delivery-title-div text-center">
                                <h1 className="text-primary">Delivery Services</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 dsc-col">
                            <div className="delivery-services-card">
                                <div className="dsc-img">
                                    <img src="/img/ds1.png" alt="img" className="img-fluid"/>
                                </div>
                                <div className="dsc-content">
                                    <h3 className="text-primary">Flexio Shift</h3>
                                    <p>
                                        Designed for those delivering to multiple stops or with high volume of packages.
                                    </p>
                                    {/*<a href="#" className="dsc-btn">*/}
                                    {/*    <i className="fas fa-sort-down"></i>*/}
                                    {/*</a>*/}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 dsc-col">
                            <div className="delivery-services-card">
                                <div className="dsc-img">
                                    <img src="/img/ds2.png" alt="img" className="img-fluid"/>
                                </div>
                                <div className="dsc-content">
                                    <h3 className="text-primary">Flexio Now</h3>
                                    <p>
                                        Designed for those Rush deliveries that need to be done right away.
                                    </p>
                                    {/*<a href="#" className="dsc-btn">*/}
                                    {/*    <i className="fas fa-sort-down"></i>*/}
                                    {/*</a>*/}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 dsc-col">
                            <div className="delivery-services-card">
                                <div className="dsc-img">
                                    <img src="/img/ds3.png" alt="img" className="img-fluid"/>
                                </div>
                                <div className="dsc-content">
                                    <h3 className="text-primary">Flexio Pool</h3>
                                    <p>
                                        Designed for those with low & high volume that needs to delivery long distances.
                                    </p>
                                    {/*<a href="#" className="dsc-btn">*/}
                                    {/*    <i className="fas fa-sort-down"></i>*/}
                                    {/*</a>*/}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 dsc-col">
                            <div className="delivery-services-card">
                                <div className="dsc-img">
                                    <img src="/img/ds4.png" alt="img" className="img-fluid"/>
                                </div>
                                <div className="dsc-content">
                                    <h3 className="text-primary">Flexio Same Day</h3>
                                    <p>
                                        Designed for those with low volume that need to deliver today.
                                    </p>
                                    {/*<a href="#" className="dsc-btn">*/}
                                    {/*    <i className="fas fa-sort-down"></i>*/}
                                    {/*</a>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="delivery-result-div">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="delivery-title-div text-center">
                                <h1 className="text-primary mb-2 mb-sm-2 mb-md-4">Delivering Results</h1>
                                <p>
                                    We are having success in many different industries and from low volume to high
                                    volume clients. Our Unique Hybrid Approach has been ideal for businesses who are
                                    already doing deliveries but they need to better manage their team and as well grow
                                    without having to recruit more people internally, as well as businesses who are just
                                    getting started to do deliveries. Our Customer Satisfaction and experience has been
                                    unmatched. We are also Hipaa Compliant.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="delivery-result-list">
                                <div className="drl-box">
                                    <div className="drl-icon">
                                        <img src="/img/dr1.png" alt="icon" className="img-fluid"/>
                                    </div>
                                    <div className="drl-text">
                                        <h5 className="text-center mt-2">
                                            Distribution
                                        </h5>
                                    </div>
                                </div>

                                <div className="drl-box">
                                    <div className="drl-icon">
                                        <img src="/img/dr2.png" alt="icon" className="img-fluid"/>
                                    </div>
                                    <div className="drl-text">
                                        <h5 className="text-center mt-2">
                                            Individuals
                                        </h5>
                                    </div>
                                </div>
                                <div className="drl-box">
                                    <div className="drl-icon">
                                        <img src="/img/dr3.png" alt="icon" className="img-fluid"/>
                                    </div>
                                    <div className="drl-text">
                                        <h5 className="text-center mt-2">
                                            Retail
                                        </h5>
                                    </div>
                                </div>
                                <div className="drl-box">
                                    <div className="drl-icon">
                                        <img src="/img/dr4.png" alt="icon" className="img-fluid"/>
                                    </div>
                                    <div className="drl-text">
                                        <h5 className="text-center mt-2">
                                            E-commerce
                                        </h5>
                                    </div>
                                </div>
                                <div className="drl-box">
                                    <div className="drl-icon">
                                        <img src="/img/dr5.png" alt="icon" className="img-fluid"/>
                                    </div>
                                    <div className="drl-text">
                                        <h5 className="text-center mt-2">
                                            Boutiques
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="get-started-div bg-primary2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="get-started-title">
                                <h6 className="text-primary">
                                    GET STARTED
                                </h6>
                                <h1>
                                    Get your deliveries done today.
                                </h1>
                            </div>
                            <div className="get-started-btn">
                                <a href={REQUEST_FOR_DEMO} className="btn btn-primary">
                                    Request Demo
                                </a>
                                <a href={FREE_TRIAL} className="btn btn-outline-light">
                                    Start a Free Trial
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Delivery;