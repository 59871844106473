import { useState } from "react";
import { blogs } from "../utils/blogs";

function Resources() {
  const [resources, setResources] = useState(
    blogs.sort((a, b) => b.img.localeCompare(a.img))
  );
  const bgs = [
    "bg-primary",
    "bg-primary2",
    "bg-orange",
    "bg-gray",
    "bg-yellow",
    "bg-sky",
  ];
  
  return (
    <>
      <div className="home-banner-div">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="banner-content">
                <h1 className="extrabold text-white text-center">Resources</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="who-it-work-div">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="hiw-tile text-center">
                <h1 className="text-primary">
                  Welcome to Flexio Resource Center
                </h1>
                <p className="text-primary">
                  Access blog posts, case studies, press releases,
                  <br /> and FAQs to enhance your delivery solutions.
                </p>
              </div>
              <div className="resource-filter-div">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <select className="form-control">
                        <option>Filter by Category</option>
                        <option>Category 1</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <select className="form-control">
                        <option>Filter by Date</option>
                        <option>Category 1</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <select className="form-control">
                        <option>Filter by Industries</option>
                        <option>Category 1</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="resorce-listing-div">
                <div className="resource-top">
                  <div className="row">
                    {resources.slice(0, 2).map((item, index) => (
                      <div
                        className={`col-md-6 col-lg-6 ${
                          index === 0 ? "col-xl-8" : "col-xl-4"
                        } mb-4`}
                      >
                        <div className="resource-card">
                          <div
                            className={`resource-img ${
                              bgs[Math.floor(Math.random() * bgs.length)]
                            }`}
                          >
                            {item.img && (
                              <img
                                alt={item.slug}
                                className={"img-fluid h-100 w-100"}
                                src={item.img}
                              />
                            )}
                          </div>
                          <div className="hw-content resource-content">
                            <h4 className={"text-start"}>{item.title}</h4>
                            <p>{item.smallDescription}</p>
                            <a
                              href={`/blog/${item.slug}`}
                              className="btn btn-primary"
                            >
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="resource-bottom">
                  <div className="row">
                    {resources.slice(2).map((item) => (
                      <div className="col-md-6 col-lg-6 col-xl-4 mb-4">
                        <div className="resource-card">
                          <div
                            className={`resource-img ${
                              bgs[Math.floor(Math.random() * bgs.length)]
                            }`}
                          ></div>
                          <div className="hw-content resource-content">
                            <h4 className={"text-start"}>{item.title}</h4>
                            <p>{item.smallDescription}</p>
                            <a
                              href={`/blog/${item.slug}`}
                              className="btn btn-primary"
                            >
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Resources;
