import {useEffect} from "react";

function ContactUs() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @TS-ignore
            if ((window as any).hbspt) {
                // @TS-ignore
                (window as any).hbspt.forms.create({
                    region: "na1",
                    portalId: "40168461",
                    formId: "4e816b10-20d3-4629-bb83-d2d647f88f44",
                    target: '#hubspotForm'
                })
            }
        });
    }, []);

    /* function chunkArray(arr: any[], size: number) {
        return arr.reduce((result, item, index) => {
            const chunkIndex = Math.floor(index / size);
            if (!result[chunkIndex]) {
                result[chunkIndex] = []; // Start a new chunk
            }
            result[chunkIndex].push(item);
            return result;
        }, []);
    } */

    return (
        <>
            <div className="home-banner-div">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner-content">
                                <h1 className="extrabold text-white text-center">
                                    Contact Us
                                    <p>For best service, please email us at info@goflexio.com</p>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="delivery-form-div">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="hiw-tile text-center pb-0 pb-sm-0 pb-md-5">
                                <h2 className="text-primary">
                                    Ready to transform your delivery experience?
                                </h2>
                                <p className="text-primary">
                                    Start optimizing your logistics and delivery processes with Flexio.
                                </p>
                            </div>
                            <div className="delivery-form">
                                <form id={'hubspotForm'}></form>
                                {/*<form>*/}
                                {/*    <div className="row">*/}
                                {/*        <div className="col-md-6">*/}
                                {/*            <div className="form-group">*/}
                                {/*                <input type="text" id="First_Name" className="form-control"*/}
                                {/*                       placeholder="First Name"/>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        <div className="col-md-6">*/}
                                {/*            <div className="form-group">*/}
                                {/*                <input type="text" id="Last_name" className="form-control"*/}
                                {/*                       placeholder="Last Name"/>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        <div className="col-md-12">*/}
                                {/*            <div className="form-group">*/}
                                {/*                <input type="email" id="Email" className="form-control"*/}
                                {/*                       placeholder="Email"/>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        <div className="col-md-12">*/}
                                {/*            <div className="form-group">*/}
                                {/*                <textarea id="Questions" className="form-control"*/}
                                {/*                          placeholder="Your questions"></textarea>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        <div className="col-md-12">*/}
                                {/*            <div className="form-btn-div">*/}
                                {/*                <button className="btn btn-primary w-100">*/}
                                {/*                    Contact us*/}
                                {/*                </button>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</form>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactUs