import { FREE_TRIAL, REQUEST_FOR_DEMO } from "../utils/links";
import { blogs } from "../utils/blogs";

function Home() {
  return (
    <>
      <div className="home-banner-div homepage-padding">
        <div className="mobile-banner text-end d-block d-sm-block d-md-none">
          <img
            src="/img/mobile-banner-img.png"
            alt="img"
            className="img-fluid"
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner-content text-center text-sm-center text-md-start">
                <h1 className="extrabold text-white">
                  The New Standard in Routing & Driver Management
                </h1>
                <p className="text-white">
                  Optimize your delivery operations with Flexio's advanced route
                  planning software, ensuring efficient and timely deliveries.
                  Our outsourcing overflow management service effortlessly
                  handles demand spikes, maintaining peak efficiency without
                  extra costs.
                </p>
                <div className="banner-btn-div">
                  <a href={FREE_TRIAL} className="btn btn-primary">
                    Start a Free Trial
                  </a>
                  <a href={REQUEST_FOR_DEMO} className="btn btn-outline-light">
                    Request Demo
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-img-div d-none d-sm-none d-md-block">
          <img src="/img/banner-img.png" alt="img" className="img-fluid" />
        </div>
      </div>
      <div className={"benifits"}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="wel-title-div text-center mt-5">
                <h2 className="text-primary align-items-center d-flex d-md-none justify-content-center">
                  <img
                    src="/img/clock-icon-blue.svg"
                    alt="icon"
                    className="img-fluid me-3"
                  />
                  Benefits
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="px-5">
          <div className="row">
            <div className="col-12 co-sm-6 col-md-6 col-lg-3">
              <div className="how-we-card">
                <div className="hw-content">
                  <h4>
                    Reduce Manual Processes & Time by <span>over 90%</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-12 co-sm-6 col-md-6 col-lg-3">
              <div className="how-we-card">
                <div className="hw-content">
                  <h4>
                    Increase Driver Capacity by <span>over 30%</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-12 co-sm-6 col-md-6 col-lg-3">
              <div className="how-we-card">
                <div className="hw-content">
                  <h4>Enable Growth & Scale your business easily</h4>
                </div>
              </div>
            </div>
            <div className="col-12 co-sm-6 col-md-6 col-lg-3">
              <div className="how-we-card">
                <div className="hw-content">
                  <h4>Provide Next-Level Customer and Driver Experience</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="welcome-div">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="wel-title-div text-center">
                <h2 className="text-white">Welcome </h2>
                <p className="text-white">
                  Enhance deliveries with Flexio's advanced route planning
                  software. Ensure efficiency with real-time monitoring. Easily
                  manage demand spikes with overflow management, maintaining
                  peak efficiency without extra costs.
                </p>
              </div>
            </div>
          </div>
          <div className="mb-5 pb-0 pb-sm-0 pb-md-4">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="welcome-img">
                  <img
                    src="/img/welcome-lef.png"
                    alt="img"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="welcome-content">
                  <h4>Route Planning and Optimization Software</h4>
                  <div className="welcome-point-div">
                    <div className="welcome-point">
                      <div className="wp-icon">
                        <img
                          src="/img/clock-icon.svg"
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>
                      <div className="wp-text">
                        <p>
                          Elevate fleet management with unparalleled efficiency
                          using our industry-leading technology.
                        </p>
                      </div>
                    </div>
                    <div className="welcome-point">
                      <div className="wp-icon">
                        <img
                          src="/img/clock-icon.svg"
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>
                      <div className="wp-text">
                        <p>
                          Customize your workflow seamlessly to ensure full
                          compliance with ease and efficiency.
                        </p>
                      </div>
                    </div>
                    <div className="welcome-point">
                      <div className="wp-icon">
                        <img
                          src="/img/clock-icon.svg"
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>
                      <div className="wp-text">
                        <p>
                          Empower your team and enhance decision-making with our
                          unparalleled data analytics, tailored for optimal
                          performance.
                        </p>
                      </div>
                    </div>
                    <div className="welcome-more-btn">
                      <a href="/saas" className="text-white">
                        Learn More <i className="fas fa-chevron-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-2">
            <div className="row align-items-center">
              <div className="col-md-6 order-2 order-sm-2 order-md-1">
                <div className="welcome-content">
                  <h4>Outsourcing Overflow Management</h4>
                  <div className="welcome-point-div">
                    <div className="welcome-point">
                      <div className="wp-icon">
                        <img
                          src="/img/clock-icon.svg"
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>
                      <div className="wp-text">
                        <p>
                          Streamline your operations during demand surges
                          without the hassle of managing additional resources.
                        </p>
                      </div>
                    </div>
                    <div className="welcome-point">
                      <div className="wp-icon">
                        <img
                          src="/img/clock-icon.svg"
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>
                      <div className="wp-text">
                        <p>
                          Access a versatile fleet, ranging from vans to compact
                          cars, catering to diverse transportation needs.
                        </p>
                      </div>
                    </div>
                    <div className="welcome-point">
                      <div className="wp-icon">
                        <img
                          src="/img/clock-icon.svg"
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>
                      <div className="wp-text">
                        <p>
                          Benefit from flexible arrangements with no minimum
                          commitments—utilize services as needed, minimizing
                          unnecessary expenses.
                        </p>
                      </div>
                    </div>
                    <div className="welcome-more-btn">
                      <a href="/delivery" className="text-white">
                        Learn More <i className="fas fa-chevron-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 order-1 order-sm-1 order-md-2">
                <div className="welcome-img">
                  <img
                    src="/img/welcome-right.png"
                    alt="img"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="many-solution-div bg-primary2">
        <div className="row m-0">
          <div className="col-md-12 order-2 order-sm-2 order-md-1 p-0">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="ms-content">
                    <h2 className="text-primary">Many Solutions, One Flexio</h2>
                    <p className="text-primary">
                      We created our proprietary technology and services
                      flexible by design. This enables the application of many
                      solutions in many different areas easily.
                    </p>
                    <a href="/resources" className="btn btn-primary">
                      Our Solutions
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 order-1 order-sm-1 order-md-2 p-0">
            <div className="many-solution-img mb-4 mb-sm-4 mb-md-0">
              <img
                src="/img/many-solutions.png"
                alt="img"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
      {/*<div className="flexio-health-div">*/}
      {/*    <div className="container">*/}
      {/*        <div className="row align-items-center">*/}
      {/*            <div className="col-md-6">*/}
      {/*                <div className="flexio-health-img">*/}
      {/*                    <img src="/img/flexio-icon.png" alt="img" className="img-fluid"/>*/}
      {/*                </div>*/}
      {/*            </div>*/}
      {/*            <div className="col-md-6">*/}
      {/*                <div className="ms-content">*/}
      {/*                    <h2 className="text-primary">*/}
      {/*                        Why Flexio*/}
      {/*                    </h2>*/}
      {/*                    <p className="text-primary">*/}
      {/*                        Discover how Flexio can revolutionize your delivery experience with unparalleled efficiency and flexibility. Learn more about our cutting-edge technology and driver-centric approach. Find out how Flexio can help you, becoming a premier choice for maximizing your earnings and achieving a better work-life balance.*/}
      {/*                    </p>*/}
      {/*                    <a href={'/health'} className="btn btn-primary">*/}
      {/*                        About Us*/}
      {/*                    </a>*/}
      {/*                </div>*/}
      {/*            </div>*/}

      {/*        </div>*/}
      {/*    </div>*/}
      {/*</div>*/}
      {/*<div className="drivers-love-div">*/}
      {/*    <div className="container">*/}
      {/*        <div className="row align-items-center">*/}
      {/*            <div className="col-md-6 order-2 order-sm-2 order-sm-1">*/}
      {/*                <div className="ms-content">*/}
      {/*                    <h2 className="text-primary">*/}
      {/*                        Drivers love us. They really do!*/}
      {/*                    </h2>*/}
      {/*                    <p className="text-primary">*/}
      {/*                        As a Flexio Driver, you’ll enjoy a unique and efficient way to maximize your*/}
      {/*                        earnings. Our platform ensures that you receive numerous deliveries, allowing you to*/}
      {/*                        make the most of your time on the road. By giving you the flexibility to manage your*/}
      {/*                        own time and workload, Flexio helps create a better work-life balance and a more*/}
      {/*                        rewarding driving experience.*/}
      {/*                    </p>*/}
      {/*                    <a href={'/become-driver'} className="btn btn-primary">*/}
      {/*                        Become a Driver*/}
      {/*                    </a>*/}
      {/*                </div>*/}
      {/*            </div>*/}
      {/*            <div className="col-md-6 order-1 order-sm-1 order-sm-2">*/}
      {/*                <div className="drivers-love-img text-end">*/}
      {/*                    <img src="/img/drivers-love.png" alt="img" className="img-fluid"/>*/}
      {/*                </div>*/}
      {/*            </div>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*</div>*/}
      <div className="how-we-think-div">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="wel-title-div text-center mb-5 pb-2">
                <h2 className="text-white mb-0">How We Think </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="how-we-slider">
          <div className="how-we-carousel owl-carousel owl-theme">
            {blogs.map((item) => (
              <div className="item">
                <div className="how-we-card">
                  <div className="hw-head">
                    {/*{item.img &&*/}
                    {/*    <img className={'img-fluid h-100'} alt={item.slug} src={item.img}/>}*/}
                  </div>
                  <div className="hw-content">
                    <h4 className="text-start">{item.title}</h4>
                    <p>{item.smallDescription}</p>
                    <a href={`/blog/${item.slug}`} className="btn btn-primary">
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="slider-learn-more text-center">
            <a href="/resources" className="btn btn-light">
              Learn More
            </a>
          </div>
        </div>

        <div className="why-flexio-div position-relative">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="ms-content">
                  <h2 className="text-white">Why Flexio</h2>
                  <p className="text-white">
                    Discover how Flexio can revolutionize your delivery
                    experience with unparalleled efficiency and flexibility.
                    Learn more about our cutting-edge technology and
                    driver-centric approach. Find out how Flexio can help you,
                    becoming a premier choice for maximizing your earnings and
                    achieving a better work-life balance.
                  </p>
                  <a href="/about-us" className="btn btn-light text-primary">
                    About Us
                  </a>
                </div>
              </div>
              <div className="col-md-6 d-block d-sm-block d-md-none why-flexio-img-mobile">
                <img
                  src="/img/why-flexio-mobile.png"
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="why-flexio-img">
            <img src="/img/why-flexio.png" alt="img" className="img-fluid" />
          </div>
        </div>
      </div>
      <div className="trusted-brands-div">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="trusted-title-div mb-4">
                <h1 className="text-primary">Trusted by Brands</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="d-none d-sm-none d-md-block">
          <div className="brand-carousel owl-carousel owl-theme">
            <div className="item">
              <div className="brand-box">
                <img src="/img/brand1.png" alt="brand" className="img-fluid" />
              </div>
            </div>
            <div className="item">
              <div className="brand-box">
                <img src="/img/brand2.png" alt="brand" className="img-fluid" />
              </div>
            </div>
            <div className="item">
              <div className="brand-box">
                <img src="/img/brand3.png" alt="brand" className="img-fluid" />
              </div>
            </div>
            <div className="item">
              <div className="brand-box">
                <img src="/img/brand4.png" alt="brand" className="img-fluid" />
              </div>
            </div>
            <div className="item">
              <div className="brand-box">
                <img src="/img/brand5.png" alt="brand" className="img-fluid" />
              </div>
            </div>
            <div className="item">
              <div className="brand-box">
                <img src="/img/brand6.png" alt="brand" className="img-fluid" />
              </div>
            </div>
            <div className="item">
              <div className="brand-box">
                <img src="/img/brand7.png" alt="brand" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
        <div className="d-block d-sm-block d-md-none">
          <div className="row justify-content-center">
            <div className="col-4 col-sm-4">
              <div className="brand-box">
                <img src="/img/brand1.png" alt="brand" className="img-fluid" />
              </div>
            </div>
            <div className="col-4 col-sm-4">
              <div className="brand-box">
                <img src="/img/brand2.png" alt="brand" className="img-fluid" />
              </div>
            </div>
            <div className="col-4 col-sm-4">
              <div className="brand-box">
                <img src="/img/brand3.png" alt="brand" className="img-fluid" />
              </div>
            </div>
            <div className="col-4 col-sm-4">
              <div className="brand-box">
                <img src="/img/brand4.png" alt="brand" className="img-fluid" />
              </div>
            </div>
            <div className="col-4 col-sm-4">
              <div className="brand-box">
                <img src="/img/brand5.png" alt="brand" className="img-fluid" />
              </div>
            </div>
            <div className="col-4 col-sm-4">
              <div className="brand-box">
                <img src="/img/brand7.png" alt="brand" className="img-fluid" />
              </div>
            </div>
            <div className="col-4 col-sm-4">
              <div className="brand-box">
                <img src="/img/brand6.png" alt="brand" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="get-started-div bg-primary2">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="get-started-title">
                <h6 className="text-primary">GET STARTED</h6>
                <h1>Get your deliveries done today.</h1>
              </div>
              <div className="get-started-btn">
                <a href={REQUEST_FOR_DEMO} className="btn btn-primary">
                  Request Demo
                </a>
                <a href={FREE_TRIAL} className="btn btn-outline-light">
                  Start a Free Trial
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
